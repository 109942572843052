import React from "react"
import { navigate, graphql, Link } from "gatsby"
import WordCloud from "react-d3-cloud"

import Layout from "../../components/en/Layout"
import Breadcrumbs from "../../components/en/Breadcrumbs"
import SEO from "../../components/en/SEO"

import ArrowIcon from "../../images/icons/Arrow"

import "./Pages.css"

const Tags = ({ data }) => {
  let wordCloud = []

  const tags = data.allTagsEn.edges.sort((a, b) =>
    a.node.name.localeCompare(b.node.name)
  )

  const cards = data.allCardsEn.edges

  const getWordCloudWidth = () => {
    if (typeof window === "undefined") return
    if (window.innerWidth > 1600) {
      return window.innerWidth / 1.84
    } else if (window.innerWidth > 1440) {
      return window.innerWidth / 1.53
    } else if (window.innerWidth > 1366) {
      return window.innerWidth / 1.38
    }
    return window.innerWidth - 50
  }

  const renderCloud = () => {
    if (typeof document === "undefined") return

    const fontSizeMapper = word => Math.log2(word.value) * 10

    return (
      <WordCloud
        width={getWordCloudWidth()}
        fontSizeMapper={fontSizeMapper}
        data={wordCloud}
        padding={2}
        onWordClick={e => navigate(`/en/hashtags/${e.slug}`)}
      />
    )
  }

  const filterCardsByTag = tag => {
    let filteredCards = []

    cards.forEach(card => {
      card.node.tags.map(t => {
        if (t.slug === tag.slug) {
          filteredCards.push(card.node)
        }
      })
    })

    wordCloud.push({
      text: tag.name,
      value: filteredCards.length,
      slug: tag.slug,
    })

    return <span>{filteredCards.length} reads</span>
  }

  return (
    <Layout>
      <SEO
        title="Hashtags"
        canonical={`${process.env.GATSBY_HOST_URL}/en/hashtags`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt/hashtags`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage="Hashtags"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>Hashtags</h1>
            <p>Find in our most used hashtags the most interesting themes</p>
          </div>

          <div className="hashtags-content">
            <div className="hashtags-cloud">{renderCloud()}</div>

            <h2>Explore</h2>
            <div className="hashtags-list">
              {tags.map(tag => (
                <Link
                  to={`/en/hashtags/${tag.node.slug}`}
                  className="hashtags-list-item"
                >
                  <h3>{tag.node.name}</h3>
                  {filterCardsByTag(tag.node)}
                  <ArrowIcon />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Tags

export const query = graphql`
  query {
    allTagsEn {
      edges {
        node {
          name
          slug
        }
      }
    }

    allCardsEn {
      edges {
        node {
          name
          slug
          tags {
            name
            slug
          }
        }
      }
    }
  }
`
